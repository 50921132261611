import React from "react";
import { useState, useEffect } from "react";
import MobileLearn from "./mobile/MobileLearn";
import LearnPage from "./web/LearnPage";

function Index() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return <>{isMobile ? <MobileLearn /> : <LearnPage />}</>;
}

export default Index;
